import React, { Fragment } from 'react';
import Proptype from 'prop-types';
import { Col } from 'reactstrap';
import config from '../../config';
import { postJson } from '../utils/customFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
class LinksWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usage_check_loaded: false,
      usageData: {}
    };
  }

  async fetchAndSet(baseUrl, groupId) {
    const filteredProducts = Object.keys(config.lookerLinkMetadata).filter(
      key => config.lookerLinkMetadata[key].usage_check_required === true
    );

    postJson(`${baseUrl}check-product-usage/${groupId}`, {
      data: filteredProducts
    })
      .then(data => {
        if (data && data.success) {
          const convertedObject = data.data.reduce((obj, item) => {
            obj[item.product] = item.usage_data;
            return obj;
          }, {});

          this.setState({
            usage_check_loaded: true,
            usageData: convertedObject
          });
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  componentDidMount() {
    if (this?.props?.groupId != null) {
      this.fetchAndSet(config.urls.getGroupDetails, this.props.groupId);
    }
  }

  renderLookerLinks(groupId) {
    const links = config.getLookerLinks(groupId);

    const linkOrder = [
      'Live',
      'App Live',
      'Automate One',
      'Selenium',
      'App Automate',
      'Browser Stats - Automate',
      'Activity',
      'Percy',
      'Cypress',
      'Browser Stats - Cypress',
      'JS',
      'Browser Stats - JS',
      'Account Insights',
      'Observability',
      'App Percy',
      'Device Features Usage',
      'Accessibility Testing',
      'Test Management',
      'Low Code Automation',
      'Turboscale'
    ];

    const results = linkOrder.map(product => (
      <Fragment key={product}>
        <a href={links[product]} target="_blank" rel="noopener noreferrer">
          {product}
          <span style={{ marginRight: '5px' }}></span>
          {config.lookerLinkMetadata[product] &&
            config.lookerLinkMetadata[product].usage_check_required == true &&
            !this.state.usage_check_loaded && (
              <FontAwesomeIcon
                className="fa-regular fa-sync fa-spin"
                icon={faSync}
                style={{ color: '#121212' }}
              />
            )}
          {config.lookerLinkMetadata[product] &&
            config.lookerLinkMetadata[product].usage_check_required == true &&
            this.state.usage_check_loaded &&
            this.state.usageData[product].usage && (
              <FontAwesomeIcon icon={faCheck} style={{ color: '#46f207' }} />
            )}
          {config.lookerLinkMetadata[product] &&
            config.lookerLinkMetadata[product].usage_check_required == true &&
            this.state.usage_check_loaded &&
            !this.state.usageData[product].usage && (
              <FontAwesomeIcon icon={faBan} style={{ color: '#e41126' }} />
            )}
        </a>
        <br />
      </Fragment>
    ));

    return results;
  }

  render() {
    const { groupId } = this.props;

    return (
      <>
        <Col>
          <h2>{'\u00A0'}</h2>
          {/* For aligning to the subscription status */}
          <h2>Links</h2>
          <h6>Looker</h6>
          {this.renderLookerLinks(groupId)}
        </Col>
      </>
    );
  }
}

LinksWidget.propTypes = {
  groupId: Proptype.string
};

export default LinksWidget;
