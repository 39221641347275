import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

/** Functional Component that is rendered if the route does not match any
 * frontend component.
 *
 * @returns {React.Component} The Page Not Found Component.
 */
export function PageNotFound() {
  return (
    <Jumbotron fluid>
      <Container fluid>
        <h1>Hey, it seems that you have found an unknown page!</h1>
        <p>
          Please send any suggestions you have for this page to Systems Team.
          :-)
          <br />
          Click <Link to="/"> here </Link>
          to go back to home page.
        </p>
      </Container>
    </Jumbotron>
  );
}
