import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import AuthCheckAndSignIn from './Components/auth';
import TabbedHome from './Components/home';
import ErrorBoundary from './Components/utils/errorBoundary';
import { GlobalStyles } from './Components/utils/_styles';
import { PageNotFound } from './Components/pageNotFound';

/** The Core mounting point of the application that links together all Components.
 *
 * @returns {React.Component} Main app
 */
function App() {
  return (
    <ErrorBoundary>
      <GlobalStyles>
        <Router>
          <AuthCheckAndSignIn>
            <div>
              <Routes>
                <Route exact path="/" element={<TabbedHome />} />
                <Route path="/group/:groupId" element={<TabbedHome />} />
                <Route path="/account/:domainName" element={<TabbedHome />} />
                <Route path="/tools" element={<TabbedHome />} />
                <Route
                  path="/mql_intelligence/:inputValue?"
                  element={<TabbedHome />}
                />
                <Route path="/settings" element={<TabbedHome />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </AuthCheckAndSignIn>
        </Router>
      </GlobalStyles>
    </ErrorBoundary>
  );
}

export default App;
