import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../utils/customFetch';
import './index.css';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faList,
  faSignOutAlt,
  faTools,
  faSearchDollar,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import logo from '../logo.png';
import config from '../../config';
import { SimpleTooltip } from '../utils/simpleToolTip';

const LogoStyle = styled.span`
  img {
    height: 2rem;
    padding: 0rem 0.4rem;
  }
`;

const NavStyle = styled.span`
  .nav-item {
    font-size: 1.1rem;
    margin-right: 0.5rem;

    &.active {
      background-color: #e5e5e5;
      border-radius: 5px;
    }
    &:hover {
      border-radius: 5px;
      background-color: #e5e5e5;
    }

    div {
      padding: 0.5rem 1rem;
    }

    .close-button {
      font-size: 0.9rem;
      color: grey;
      transition: color 0.4s;

      &:hover {
        color: crimson;
      }
    }
  }

  .toolsButton div {
    color: grey;

    &:hover {
      border-radius: 5px;
      color: blue;
      background-color: #e5e5e5;
    }
  }

  .logoutButton .nav-link {
    color: grey;

    &:hover {
      border-radius: 5px;
      color: red;
      background-color: #e5e5e5;
    }
  }
`;

/** Get the path to go to when the tab changes.
 *
 * @param {string} name The title of the tab.
 * @returns {string} The Relative Path String.
 */
function getRouteFromTabName(name) {
  if (name === 'My Watchlist') {
    return '/';
  }
  if (name === 'Tools Panel') {
    return '/tools';
  }
  if (name === 'MQL Intelligence') {
    return '/mql_intelligence';
  }
  if (name === 'Settings') {
    return '/settings';
  }
  if (/Group:[0-9]+/.test(name)) {
    const groupId = /Group:([0-9]+)/.exec(name)[1];
    return `/group/${groupId}`;
  }
  if (/Account:.+/.test(name)) {
    const domainName = /Account:(.+)/.exec(name)[1];
    return `/account/${domainName}`;
  }
  return '/';
}

/** Header Component, integrates tab with Navbar
 *
 * @returns {object} Header
 */
function Header({ tabTitles, activeTab, closeTab, openWatchList }) {
  const tabNavs = [];
  const isWatchListClosed = tabTitles.indexOf('My Watchlist') === -1;

  const [isDataValid, setIsDataValid] = useState(false);
  const [searchPath, setSearchPath] = useState('/');

  const onSubmit = event => {
    event.preventDefault();
    if (!isDataValid) {
      alert(
        'Please enter Valid string, it should be greater than 3 characters.'
      );
    } else {
      document.getElementById('search-link').click();
    }
  };

  const validateData = event => {
    let valid = true;
    const searchString = event.target.value;

    if (searchString.length <= 3) {
      valid = false;
    } else {
      if (/^\d+$/.test(searchString)) {
        setSearchPath(`/group/${searchString}`);
      } else if (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          searchString
        )
      ) {
        fetchData(
          'getGroupID',
          `${config.urls.getGroupId}${searchString}`
        ).then(groupId => {
          if (groupId) {
            setSearchPath(`/group/${groupId}`);
          }
        });
      } else {
        setSearchPath(`/account/${searchString}`);
      }
      valid = true;
    }
    setIsDataValid(valid);
  };

  if (tabTitles) {
    tabTitles.forEach(tabName => {
      tabNavs.push(
        <NavItem
          key={tabName}
          className={tabName === activeTab ? 'active' : ''}
        >
          <NavLink tag={Link} to={getRouteFromTabName(tabName)}>
            {tabName}{' '}
            {tabTitles.length > 1 && (
              <FontAwesomeIcon
                className="close-button"
                onClick={e => {
                  closeTab(tabName);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                icon={faTimesCircle}
              />
            )}
          </NavLink>
        </NavItem>
      );
    });
  }

  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand tag={Link} to="/">
        <LogoStyle>
          <img src={logo} />
        </LogoStyle>
        Hawkeye
      </NavbarBrand>
      <NavStyle>
        <Nav navbar>
          <Form inline onSubmit={onSubmit}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Input
                type="text"
                onChange={validateData}
                placeholder="Account Search"
                id="account-search"
              />
              <SimpleTooltip placement="left" target="account-search">
                Enter Domain, Group ID, email to be searched for.
              </SimpleTooltip>
            </FormGroup>
            <Link to={searchPath} type="hidden" id="search-link" />
          </Form>
          {isWatchListClosed && (
            <NavItem className="ml-auto">
              <div onClick={() => openWatchList()}>
                <FontAwesomeIcon icon={faList} />
              </div>
            </NavItem>
          )}

          {tabNavs}
        </Nav>
      </NavStyle>
      <NavStyle className={'ml-auto'}>
        <Nav>
          <NavItem key="toolsButton" className={'toolsButton'}>
            <UncontrolledDropdown style={{ padding: '0px' }}>
              <DropdownToggle caret>Features</DropdownToggle>
              <DropdownMenu
                style={{ padding: '0px' }}
                className="dropdown-menu-class"
              >
                <DropdownItem style={{ padding: '0px' }}>
                  <NavLink to={'/tools'} tag={Link}>
                    Tools <FontAwesomeIcon icon={faTools} />
                  </NavLink>
                </DropdownItem>
                <DropdownItem style={{ padding: '0px' }}>
                  <NavLink to={'/mql_intelligence'} tag={Link}>
                    MQL Intelligence <FontAwesomeIcon icon={faSearchDollar} />
                  </NavLink>
                </DropdownItem>
                <DropdownItem style={{ padding: '0px' }}>
                  <NavLink to={'/settings'} tag={Link}>
                    Settings <FontAwesomeIcon icon={faCog} />
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavItem>

          <NavItem key="logoutButton" className={'logoutButton'}>
            <NavLink href={`${config.hostname}/auth/logout`}>
              Logout <FontAwesomeIcon icon={faSignOutAlt} />
            </NavLink>
          </NavItem>
        </Nav>
      </NavStyle>
    </Navbar>
  );
}

Header.propTypes = {
  tabTitles: PropTypes.array,
  activeTab: PropTypes.string,
  closeTab: PropTypes.func,
  openWatchList: PropTypes.func
};

export default Header;
