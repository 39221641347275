import { transform } from 'lodash-es';

let port = '';
let internalHostname = 'https://hawkeye-internal.browserstack.com';
let hostname = 'https://hawkeye.browserstack.com';
if (process.env.NODE_ENV === 'development') {
  hostname = 'https://hawkeye-dev.browserstack.com';
  port = ':3000';
} else if (process.env.NODE_ENV === 'staging') {
  hostname = 'https://hawkeye.bsstag.com';
}

/**
 * Configuration
 *
 * @typedef {object} FrontendConfig
 * @property {string} frontend The url of frontend(differs from hostname in dev environment)
 * @property {string} hostname The url of hawkeye
 *
 */

const config = {
  frontend: `${hostname}${port}`,
  hostname: hostname,
  internalHostname: internalHostname,
  sfdcBaseUrl: 'https://browserstack.lightning.force.com/',
  urls: {
    authCheck: `${hostname}/auth/check/`,
    getWatchlist: `${hostname}/api/watchlist/`,
    getWatchlistOnlyGroupId: `${hostname}/api/watchlist/group-ids/`,
    getGroupDetails: `${hostname}/api/group-details/`,
    getSubgroupInfo: `${hostname}/api/subgroup-info/`,
    getUserLocation: `${hostname}/api/user-location/`,
    getOrderHistory: `${hostname}/api/orders/`,
    getFailedCharges: `${hostname}/api/charge-failed/`,
    getBuildMetrics: `${hostname}/api/build-metrics/`,
    getPowerUsers: `${hostname}/api/power-users/`,
    getNetAcv: `${hostname}/api/tools/net-acv/`,
    updateNetAcvEdits: `${hostname}/api/tools/net-acv/update-edit-fields`,
    getFreeTrials: `${hostname}/api/free-trials/`,
    getLeadInfo: `${hostname}/api/lead-info/`,

    getAccountDetails: `${hostname}/api/account-details/`,
    getGroupId: `${hostname}/api/account-details/email/`,
    getEmailPreferences: `${hostname}/api/account-details/email-preferences`,
    saveEmailPreferences: `${hostname}/api/account-details/email-preferences`,

    getGroupInfo: `${hostname}/api/one-shot-group-info/`,
    getHealthScore: `${hostname}/api/health-score/`,
    getGroupInvoices: `${hostname}/api/group-invoices/`,
    createContact: `${hostname}/api/sf-contact-create/`,
    disconnectSFDCAuth: `${hostname}/api/sf-authenticator/disconnect`,
    netAcvCreateJob: `${hostname}/api/tools/net-acv/create-job`,
    netAcvCheckStatus: `${hostname}/api/tools/net-acv/check-status`,
    netAcvUpdateEditFields: `${hostname}/api/tools/net-acv/update-edit-fields`,

    dbDumpStartJob: `${hostname}/api/tools/db-dumper/get-groups`,
    dbDumpCheckJobStatus: `${hostname}/api/tools/db-dumper/check-status`,
    dbDumpDownloadLink: `${hostname}/api/tools/db-dumper/download`,

    removeFromWatchlist: `${hostname}/api/watchlist/`, // delete
    addToWatchlist: `${hostname}/api/watchlist/`, // post
    getAllWatchlistData: `${hostname}/api/watchlist/`, // get

    fetchEnrichmentDataset: `${hostname}/api/tools/enrichment/fetch-datasets/`,
    downloadDataset: `${hostname}/api/tools/enrichment/download/`,
    startCrawling: `${hostname}/api/tools/enrichment/crawl/start`,
    uploadDataset: `${hostname}/api/tools/enrichment/upload-dataset`,
    fetchStats: `${hostname}/api/tools/enrichment/stats/`,
    deleteDataset: `${hostname}/api/tools/enrichment/delete/`,

    aRUpdate: `${hostname}/api/tools/ar-update/`
  },
  automateProducts: ['automate', 'app_automate']
};

config.pricingMapping = {
  users: 'Per User Cost',
  parallels: 'Per Parallel Cost',
  flat: 'Flat Fee Cost',
  screenshots: 'Per Screenshot Cost',
  complimentary: 'Complimentary Cost',
  overages: 'Per Overage Screenshot Cost',
  average_unit_cost: 'Average Unit Cost'
};

config.getLookerLinks = groupId => {
  const links = {
    'Automate One': `https://browserstack.looker.com/dashboards/934?Group ID=${groupId}&Sub Group ID=&Date=90 days&User Id=&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":8}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":9}],"Date":[{"type":"past","values":[{"constant":"90","unit":"day"},{}],"id":10}],"User Id":[{"type":"=","values":[{"constant":""},{}],"id":11}]}`,
    Selenium: `https://browserstack.looker.com/dashboards/166?Group ID=${groupId}&Sub Group ID=&Date=90 days&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":23}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":24}],"Date":[{"type":"past","values":[{"constant":"90","unit":"day"},{}],"id":25}]}`,
    'App Automate': `https://browserstack.looker.com/dashboards/167?Group ID=${groupId}&Sub Group ID=&Date=90 days&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":25}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":26}],"Date":[{"type":"past","values":[{"constant":"90","unit":"day"},{}],"id":27}]}`,
    'Browser Stats - Automate': `https://browserstack.looker.com/dashboards/165?Group ID=${groupId}&Sub Group ID=&Date=90 days&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":25}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":26}],"Date":[{"type":"past","values":[{"constant":"90","unit":"day"},{}],"id":27}]}`,
    Activity: `https://browserstack.looker.com/dashboards/164?Group ID=${groupId}&Sub Group ID=&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":27}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":28}]}`,
    'App Live': `https://browserstack.looker.com/dashboards/633?Group ID=${groupId}&Date=30 days&Sub Group IID=&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":3}],"Date":[{"type":"past","values":[{"constant":"30","unit":"day"},{}],"id":4}],"Sub Group IID":[{"type":"=","values":[{"constant":""},{}],"id":5}]}`,
    Live: `https://browserstack.looker.com/dashboards/632?Group ID=${groupId}&Created Date=30 days&Subgroup ID=&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":3}],"Created Date":[{"type":"past","values":[{"constant":"30","unit":"day"},{}],"id":4}],"Subgroup ID":[{"type":"=","values":[{"constant":""},{}],"id":5}]}`,
    Percy: `https://browserstack.looker.com/dashboards/1567?Browserstack+Group+Id=${groupId}&Historical Failure Count Month=30 days&Cumulative Comparison Time Range=30 days&filter_config={"Organization Group Id":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":3}],"Historical Failure Count Month":[{"type":"past","values":[{"constant":"30","unit":"day"},{}],"id":4}],"Cumulative Comparison Time Range":[{"type":"past","values":[{"constant":"30","unit":"day"},{}],"id":5}]}`,
    'App Percy': `https://browserstack.looker.com/dashboards/1597?User+Group+ID=${groupId}&Team+Name=&Project+Name=&Date+Filter=&Date+Granularity=Month`,
    Cypress: `https://browserstack.looker.com/dashboards-next/708?Group ID=${groupId}&Sub Group ID=&Date=90 days&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":23}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":24}],"Date":[{"type":"past","values":[{"constant":"90","unit":"day"},{}],"id":25}]}`,
    'Browser Stats - Cypress': `https://browserstack.looker.com/dashboards-next/709?Group ID=${groupId}&Sub Group ID=&Date=90 days&filter_config={"Group ID":[{"type":"=","values":[{"constant":"${groupId}"},{}],"id":25}],"Sub Group ID":[{"type":"=","values":[{"constant":""},{}],"id":26}],"Date":[{"type":"past","values":[{"constant":"90","unit":"day"},{}],"id":27}]}`,
    JS: `https://browserstack.looker.com/dashboards-next/722?User+User+ID=&Created+Day+Date=90+day&User+Group+ID=${groupId}&User+Sub+Group+ID=`,
    'Browser Stats - JS': `https://browserstack.looker.com/dashboards-next/723?User+User+ID=&Created+Day+Date=90+day&User+Group+ID=${groupId}&User+Sub+Group+ID=`,
    'Account Insights': `https://browserstack.looker.com/dashboards-next/744?Group+ID=${groupId}`,
    'Accessibility Testing': `https://browserstack.looker.com/dashboards/2244?Group+ID=${groupId}&AE+Name=&User+ID=&Date+Range=6+month&CSM+Name=&CE+Name=`,
    Observability: `https://browserstack.looker.com/dashboards/1556?Date+Range=3+month&User+Type+Automate=&Group+ID=${groupId}`,
    'Device Features Usage': `https://browserstack.looker.com/dashboards/1857?Date=3+month&Account+Owner=&Account+Name=&Group+ID=${groupId}&User+ID=&Features=&Team+Name=&Segment=&Channel=`,
    'Test Management': `https://browserstack.looker.com/dashboards/1910?Group+ID=${groupId}&User+ID=&Project+ID=&Created+Date=30+day`,
    'Low Code Automation': `https://browserstack.looker.com/dashboards/2063?Group+ID=${groupId}&User+ID=&Created+At+Date=7+day`,
    'Turboscale': `https://browserstack.looker.com/dashboards/2338?Group+ID=${groupId}&User+ID=&Sub+Group+ID=&Created+Day+Date=90+day`
  };

  // Encode the spaces and the other special characters in the links

  // eslint-disable-next-line no-param-reassign
  return transform(
    links,
    (r, v, k) => {
      r[k] = encodeURI(v);
    },
    {}
  );
};

config.lookerLinkMetadata = {
  Observability: {
    usage_check_required: true
  }
};

config.emailPreferenceHelperText = {
  account_summary: {
    label: 'Highlights',
    helper:
      'Quick glance of the number of accounts you own & account not running the tests.'
  },
  usage_summary: {
    label: 'Usage Summary',
    helper: 'Detailed Product-wise usage summary across all your accounts.'
  },
  account_not_running_test: {
    label: 'Account Not Running Tests',
    helper: 'List of accounts that are not running tests from last week.'
  },
  // unutilized_parallels: {
  //   label:'Account Not Utilizing Users/Parallels',
  //   helper:'Subscription started before 3 months and haven\'t utilised 50% of parallels/users.'
  // },
  low_mal_account: {
    label: 'Accounts with low MAL Score',
    helper: 'List of accounts with less than 50% MAL'
  },
  opp_closing_current_week: {
    label: 'Opportunities Closing this week',
    helper: 'Opportunities with Close date in Current Week'
  },
  renewal_opp_with_upcoming_trd: {
    label: 'Renewal Opportunities with TRD in coming 30 days',
    helper: 'Renewal Opportunities with Renewal Date in coming 30 days'
  },
  stale_opps: {
    label: 'Stale Opportunities',
    helper: 'List of opportunities with close date in the past.'
  },
  open_support_tickets: {
    label: 'Freshdesk Open Support Ticket',
    helper: 'Open Support tickets across all your accounts'
  },
  report_links: {
    label: 'Links to Reports',
    helper: 'Links to all the reports viz Free Trial, Documentation Read'
  },
  open_hrc_accounts: {
    label: 'Account with Open HRC',
    helper: 'Accounts with any Open HRC'
  },
  missing_stakeholder: {
    label: 'Missing Stakeholders',
    helper: 'Internal and External Stakeholder is missing (Report)'
  },
  low_healthscore_accounts: {
    label: 'Account with Low HealthScore',
    helper: 'List of Accounts with HealthScore less than 40%'
  },
  purchased_from_accounts_online: {
    label: 'Purchased via SS',
    helper: 'Purchases from their accounts (Matched via Group ID)'
  },
  running_multiple_freemium: {
    label: 'Multiple Products Usage in their Free Trial',
    helper: 'If a lead has used more that 1 product during their free trials'
  },
  onboarding_and_adoption: {
    label: 'Onboarding And Adoption - Utilization',
    helper:
      "Subscription started before 3 months and haven't utilised 50% of parallels/users."
  },
  ss_acounts_created: {
    label: 'SS Accounts Created Last Week',
    helper: 'List of SS accounts that created last week'
  },
  dormant_users: {
    label: 'Dormant Users',
    helper:
      'Dormant Users who did some automate sessions and went through MQL Documentation in last week'
  },
  inactive_account_users_read_doc: {
    label: 'Users in unpaid Accounts reading docs',
    helper:
      'Users in unpaid Accounts who went through MQL Documentation in last week'
  },
  usage_change_data: {
    label: 'User Queued Tests & Parallel Usage Change',
    helper: 'Users who changed Queued Tests & Parallel Usage > 10%'
  },
  framework_change_data: {
    label: 'Framework Usage Change',
    helper: 'Users who changed Framework in last week'
  }
};

export default config;
